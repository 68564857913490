import VuexPersistence from 'vuex-persist'
const vuexLocal = new VuexPersistence({
    key: "vuex",
    storage: window.localStorage
  })
const User = {
    state:{
        isAuth:false,
        userData:null,
        
    },
    getters:{},
    mutations:{
        setAuth(state,value){
            state.isAuth = value
        },
        setUserData(state,value){
            state.userData = value
        }
    },
    actions:{},
    plugins:[vuexLocal.plugin ]
}

export default User