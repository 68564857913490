export default [
    {
        path:'/login',
        component: () => import ("../views/Login/Context.vue"),
        children:[
            {
                path:"/login",
                component: () => import("../views/Login/Login.vue")
            },
        ]
    }
]