import { createRouter, createWebHistory,RouteRecordRaw } from 'vue-router'
import Dashboard from './DashboardRoutes'
import LoginRoutes from './LoginRoutes'
import HomeRoutes from './HomeRoutes'
const routes: Array<RouteRecordRaw> = [
  ...LoginRoutes,
  ...Dashboard,
  ...HomeRoutes
 ]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
