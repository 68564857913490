import { createStore } from 'vuex'
import User from './modules/User'
import createPersistedState from "vuex-persistedstate";
export default  createStore({
  state: {
    msg:"Hola",
    count: 0
  },
  mutations: {
    increment: state => state.count++,
    decrement: state => state.count--
  },
  actions: {
  },
  modules: {
    user:User
  },
  plugins: [createPersistedState()]
})
