export default [
    {
        path:'/',
        component:()=>import("../views/Inicio.vue")
    },
    {
        path:'/dashboard',
        component: () => import ("../views/Dashboard/Context.vue"),
        children:[
            {
                path:"/dashboard",
                component: () => import("../views/Dashboard/Home.vue")
            },
            {
                path:'/dashboard/catalogo/almacenes',
                component:()=>import ("@/views/Dashboard/Catalogo/Almacenes.vue")
            },
            {
                path:'/dashboard/catalogo/pacientes',
                component:()=>import ("@/views/Dashboard/Catalogo/Pacientes.vue")
            },
            {
                path:'/dashboard/catalogo/cajas',
                component:()=>import ("@/views/Dashboard/Catalogo/Cajas.vue")
            },
            {
                path:'/dashboard/catalogo/productos',
                component:()=>import ("@/views/Dashboard/Catalogo/Productos.vue")
            },
            {
                path:'/dashboard/catalogo/medicos',
                component:()=>import ("@/views/Dashboard/Catalogo/Doctores.vue")
            },
            {
                path:'/dashboard/catalogo/proveedores',
                component:()=>import ("@/views/Dashboard/Catalogo/Proveedores.vue")
            },
            {
                path:'/dashboard/catalogo/lineas',
                component:()=>import ("@/views/Dashboard/Catalogo/Lineas.vue")
            },
            {
                path:'/dashboard/catalogo/departamentos',
                component:()=>import ("@/views/Dashboard/Catalogo/Departamentos.vue")
            },
            {
                path:'/dashboard/catalogo/habitaciones',
                component:()=>import ("@/views/Dashboard/Catalogo/Camas.vue")
            },
            {
                path:'/dashboard/catalogo/especialidades',
                component:()=>import ("@/views/Dashboard/Catalogo/Especialidades.vue")
            },
            {
                path:'/dashboard/catalogo/movimientos',
                component:()=>import ("@/views/Dashboard/Catalogo/Movimientos.vue")
            },
            {
                path:'/dashboard/catalogo/causas-cancelacion',
                component:()=>import ("@/views/Dashboard/Catalogo/Cancelaciones.vue")
            },
            {
                path:'/dashboard/catalogo/empleados',
                component:()=>import ("@/views/Dashboard/Catalogo/Empleados.vue")
            },
            {
                path:'/dashboard/catalogo/cirugias',
                component:()=>import ("@/views/Dashboard/Catalogo/Cirugia.vue")
            },
            {
                path:'/dashboard/catalogo/paquetes',
                component:()=>import ("@/views/Dashboard/Catalogo/Paquetes.vue")
            },
            {
                path:'/dashboard/catalogo/paquetes-producto/:id',
                props:true,
                name:"PaqueteProducto",
                component:()=>import ("@/views/Dashboard/Catalogo/PaquetesProducto.vue")
            },
            {
                path:'/dashboard/catalogo/usuarios',
                component:()=>import ("@/views/Dashboard/Catalogo/Usuarios.vue")
            },
            {
                path:'/dashboard/catalogo/instituciones',
                component:()=>import ("@/views/Dashboard/Catalogo/Instituciones.vue")
            },
            {
                path:'/dashboard/catalogo/formas-pago',
                component:()=>import ("@/views/Dashboard/Catalogo/FormasPago.vue")
            },
            {
                path:'/dashboard/catalogo/sublineas',
                component:()=>import ("@/views/Dashboard/Catalogo/SubLineas.vue")
            },
            {
                path:'/dashboard/enfermeria/vale-de-consumo',
                component:()=>import ("@/views/Dashboard/Enfermeria/ValeConsumo.vue")
            },
            {
                path:'/dashboard/enfermeria/vale-de-devolucion',
                component:()=>import ("@/views/Dashboard/Enfermeria/ValeDevolucion.vue")
            },
            {
                path:'/dashboard/enfermeria/vale-de-consumo-interno',
                component:()=>import ("@/views/Dashboard/Enfermeria/ValeConsumoInterno.vue")
            },
            {
                path:'/dashboard/enfermeria/consulta-de-consumos-internos',
                component:()=>import ("@/views/Dashboard/Enfermeria/ConsultaConsumoInterno.vue")
            },
            {
                path:'/dashboard/enfermeria/consulta-de-consumos',
                component:()=>import ("@/views/Dashboard/Enfermeria/ConsultaConsumo.vue")
            },
            {
                path:'/dashboard/enfermeria/consulta-de-devoluciones',
                component:()=>import ("@/views/Dashboard/Enfermeria/ConsultaDevoluciones.vue")
            },
            {
                path:'/dashboard/enfermeria/generar/vale-de-consumo',
                component:()=>import ("@/views/Dashboard/Enfermeria/GenerarValePDF.vue")
            },
            {
                path:'/dashboard/enfermeria/impresion-vales',
                component:()=>import("@/views/Dashboard/Enfermeria/ImpresionVales.vue")
            },
            {
                path:'/dashboard/admision/archivo-de-admisines',
                component:()=>import ("@/views/Dashboard/Admision/Hospitalizacion.vue")
            },
            {
                path:'/dashboard/admision/alta-paciente',
                component:()=>import ("@/views/Dashboard/Admision/AltaPaciente.vue")
            },
            {
                path:'/dashboard/almacen/entrada-de-productos',
                component:()=>import ("@/views/Dashboard/Almacen/EntradaAlmacen.vue")
            },
            {
                path:'/dashboard/almacen/salida-de-productos',
                component:()=>import ("@/views/Dashboard/Almacen/SalidaAlmacen.vue")
            },
            {
                path:'/dashboard/compras/orden-de-compra',
                component:()=>import ("@/views/Dashboard/Compras/OrdenCompra.vue")
            },
            {
                path:'/dashboard/caja/caja',
                component:()=>import ("@/views/Dashboard/Caja/Caja.vue")
            },
            {
                path:'/dashboard/caja/estado-cuenta',
                component:()=>import ("@/views/Dashboard/Caja/EstadoCuentaPaciente.vue")
            },
            {
                path:'/dashboard/quirofano/monitor',
                component:()=>import ("@/views/Dashboard/Quirofano/Monitor.vue")
            },
            {
                path:'/dashboard/caja/facturas',
                component:()=>import ("@/views/Dashboard/Caja/Facturas.vue")
            },
            {
                path:'/dashboard/caja/crear-factura',
                component:()=>import ("@/views/Dashboard/Caja/Facturacion.vue")
            },
            {
                path:'/dashboard/test',
                component:()=>import ("@/views/test/Test.vue")
            },
        ]
    }
]
