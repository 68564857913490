import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';

import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'  
import './assets/styles/global-styles.scss'
/*PRIMEVUE*/
import InputText from 'primevue/inputtext'; 
import Password from 'primevue/password';
import InputSwitch from 'primevue/inputswitch';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Avatar from 'primevue/avatar';
import Menubar from 'primevue/menubar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';    
import Card from 'primevue/card';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Dialog from 'primevue/dialog';
import Sidebar from 'primevue/sidebar';
import Message from 'primevue/message';
import Toolbar from 'primevue/toolbar';
import Tooltip from 'primevue/tooltip';
import InlineMessage from 'primevue/inlinemessage';
import Divider from 'primevue/divider';
import ProgressSpinner from 'primevue/progressspinner';
import SelectButton from 'primevue/selectbutton';
import Listbox from 'primevue/listbox';
import RadioButton from 'primevue/radiobutton';
import OverlayPanel from 'primevue/overlaypanel';
import Menu from 'primevue/menu';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
createApp(App)
.component('TabView',TabView)
.component('TabPanel',TabPanel)
.component('InputText',InputText)
.component('Password',Password)
.component('InputSwitch',InputSwitch)
.component('InputMask',InputMask)
.component('InputNumber',InputNumber)
.component('Button',Button)
.component('Avatar',Avatar)
.component('Menubar',Menubar)
.component('DataTable',DataTable)
.component('Column',Column)
.component('ColumnGroup',ColumnGroup)
.component('ConfirmDialog',ConfirmDialog)
.component('Card',Card)
.component('Checkbox',Checkbox)
.component('Dropdown',Dropdown)
.component('Dialog',Dialog)
.component('Sidebar',Sidebar)
.component('Toast',Toast)
.component('Toolbar',Toolbar)
.component('Message',Message)
.component('InlineMessage',InlineMessage)
.component('ProgressSpinner',ProgressSpinner)
.component('Row',Row)
.component('Divider',Divider)
.component('SelectButton',SelectButton)
.component('Calendar',Calendar)
.component('Listbox',Listbox)
.component('OverlayPanel',OverlayPanel)
.component('RadioButton',RadioButton)
.component('Menu', Menu)
.directive('tooltip', Tooltip)
.use(PrimeVue)
.use(ConfirmationService)
.use(ToastService)
.use(store)
.use(router)
.mount('#app')
